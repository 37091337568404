import { Module, VuexModule, VuexMutation, getModule, VuexAction } from 'nuxt-property-decorator';
import { AxiosError, AxiosResponse } from 'axios';
import { store } from '@/store';
import { $axios } from '@/utils/api';

import { getAuthHeader, getBrowserData } from '@/_helpers/misc_helper';

import Booking, { International } from '@/types/booking/booking';
import { ClientInfo } from '@/types/booking';

import { ErrorResponse, ListRequestFilter, ListRequestOrder, buildListQuery, stringifyExpands } from '~/types/api_helper';
import Upgrade from '@/types/booking/upgrade';

@Module({
	name: 'BOOKING',
	store, // this basically injects the module in the store dynamically thanks to next line
	dynamic: true,
	stateFactory: true // apparently necessary/better for Nuxt
})
class BOOKING extends VuexModule {
	data = new Booking();

	bookingPartnerName = '';

	// ------------------------------------------------
	// ------------- Mutations ------------------------
	// ------------------------------------------------
	// Once decorated with the @Mutation decorator Mutations are instanciated with 'this' (context) set to the state
	// So when you want to change things in the state, state.item++ is simply this.item++

	@VuexMutation
	storeBooking(payload: Booking) {
		this.data = payload;
	}

	@VuexMutation
	clearBooking() {
		this.data = new Booking();
	}

	@VuexMutation
	addBookingPartnerName(payload: string) {
		this.bookingPartnerName = payload;
	}

  @VuexMutation
	clearBookingPartnerName() {
		this.bookingPartnerName = '';
	}

	// ------------------------------------------------
	// ------------- Actions --------------------------
	// ------------------------------------------------
	// 'context' is passed by default in the actions as 'this.context'

	@VuexAction
	CLEAR_BOOKING_STORED() {
		this.context.commit('clearBooking');
	}

	@VuexAction({ rawError: true })
	async CREATE_BOOKING(booking: Booking): Promise<Booking | ErrorResponse> {
		const url = 'v2/booking';
		const auth = getAuthHeader();

		return await $axios
			.post(url, booking.toPayload(), auth)
			.then((res: AxiosResponse) => {
				return new Booking(res.data);
			})
			.catch((err: AxiosError) => {
				return ErrorResponse.fromAxiosError(err);
			});
	}

	@VuexAction({ rawError: true })
	async ADD_INTERNATIONAL(params: { id: string; zone: Partial<International> }): Promise<Boolean | ErrorResponse> {
		const url = `v2/booking/${params.id}/international`;
		const auth = getAuthHeader();

		return await $axios
			.post(url, params.zone, auth)
			.then((_res: AxiosResponse) => {
				return true;
			})
			.catch((err: AxiosError) => {
				return ErrorResponse.fromAxiosError(err);
			});
	}

	@VuexAction({ rawError: true })
	async REMOVE_INTERNATIONAL(params: { booking_id: string; int_id: string }): Promise<Boolean | ErrorResponse> {
		const url = `v2/booking/${params.booking_id}/international/${params.int_id}`;
		const auth = getAuthHeader();

		return await $axios
			.delete(url, auth)
			.then((res: AxiosResponse) => {
				console.log(res.data);
				return true;
			})
			.catch((err: AxiosError) => {
				return ErrorResponse.fromAxiosError(err);
			});
	}

	@VuexAction({ rawError: true })
	async UPDATE_BOOKING(params: { booking_id: string; booking: Booking }): Promise<Booking | ErrorResponse> {
		const auth = getAuthHeader();
		const expand = ['international', 'driver', 'step', 'billing', 'documents', 'deposit', 'tracking'];
		const url = `v2/booking/${params.booking_id}` + stringifyExpands(expand);

		return await $axios
			.put(url, params.booking.toPayload(), auth)
			.then((res: AxiosResponse) => {
				this.context.commit('storeBooking', new Booking(res.data));
				return new Booking(res.data);
			})
			.catch((err: AxiosError) => {
				return ErrorResponse.fromAxiosError(err);
			});
	}

	@VuexAction({ rawError: true })
	async GET_BOOKING(params: { id: string, add_expand?: string[] }): Promise<Booking | ErrorResponse> {
		const auth = getAuthHeader();
		let expand = ['international', 'driver', 'step', 'billing', 'documents', 'deposit', 'tracking'];
		if (params.add_expand) {
			expand = [...expand, ...params.add_expand]
		}

		const url = `v2/booking/${params.id}` + stringifyExpands(expand);

		return await $axios
			.get(url, auth)
			.then((res: AxiosResponse) => {
				this.context.commit('storeBooking', new Booking(res.data));
				return new Booking(res.data);
			})
			.catch((err: AxiosError) => {
				return ErrorResponse.fromAxiosError(err);
			});
	}

	@VuexAction({ rawError: true })
	async CANCEL_BOOKING(params: { id: string; type: string }): Promise<boolean | ErrorResponse> {
		const url = `v2/booking/${params.id}/cancel?type=${params.type}`;
		const auth = getAuthHeader();

		return await $axios
			.delete(url, auth)
			.then((_res: AxiosResponse) => {
				return true
			})
			.catch((err: AxiosError) => {
				return ErrorResponse.fromAxiosError(err);
			});
	}

	@VuexAction({ rawError: true })
	async APPLY_VOUCHER_BOOKING(params: { booking_id: string; voucher: string }): Promise<boolean | ErrorResponse> {
		const url = `v2/booking/${params.booking_id}/voucher?code=${params.voucher}`
		const auth = getAuthHeader();

		return await $axios
			.put(url, {}, auth)
			.then((res: AxiosResponse) => {
				return res.data;
			})
			.catch((err: AxiosError) => {
				return ErrorResponse.fromAxiosError(err);
			});
	}

	@VuexAction({ rawError: true })
	async APPLY_VOUCHER_UPGRADE(params: { booking_id: string; upgrade_id: string; voucher: string }): Promise<Upgrade | ErrorResponse> {
		const auth = getAuthHeader();
		const expands = ['billing', 'booking:min'];

		const url = `v2/booking/${params.booking_id}/upgrade/${params.upgrade_id}/voucher` + stringifyExpands(expands);
		return await $axios
			.put(url, { code: params.voucher }, auth)
			.then((res: AxiosResponse) => {
				return new Upgrade(res.data);
			})
			.catch((err: AxiosError) => {
				return ErrorResponse.fromAxiosError(err);
			});
	}

	@VuexAction({ rawError: true })
	async GET_OFFER(id: string): Promise<string | ErrorResponse> {
		const url = `v2/booking/${id}/offer`;
		const auth = getAuthHeader();
		auth.responseType = 'blob';

		return await $axios
			.get(url, auth)
			.then((res: AxiosResponse) => {
				return URL.createObjectURL(res.data);
			})
			.catch(async (err: AxiosError) => {
				const error = JSON.parse(await err.response?.data.text()); // convert blob to text to json
				return new ErrorResponse(error);
			});
	}

	@VuexAction({ rawError: true })
	async GET_CONTRACT_PREVIEW(booking_id: string): Promise<string | ErrorResponse> {
		const url = `v2/booking/${booking_id}/contract-draft`;
		const auth = getAuthHeader();
		auth.responseType = 'blob';

		return await $axios
			.get(url, auth)
			.then((res: AxiosResponse) => {
				return URL.createObjectURL(res.data);
			})
			.catch(async (err: AxiosError) => {
				const error = JSON.parse(await err.response?.data.text()); // convert blob to text to json
				return new ErrorResponse(error);
			});
	}

	@VuexAction({ rawError: true })
	async PAYMENT_CLICKED(params: { booking_id: string, gclid?: string, msclkid?:string, sklik_id?: string, google_client_id?: string }): Promise<void> {
		const auth = getAuthHeader();
		let url = `v2/booking/${params.booking_id}/checkout`;

		const p = new URLSearchParams() as any;
		if (params.gclid) {
			p.append('gclid', params.gclid);
		}
		if (params.msclkid) {
			p.append('msclkid', params.msclkid);
		}
		if (params.google_client_id) {
			p.append('google_client_id', params.google_client_id);
		}
		if (params.sklik_id) {
			p.append('sklik_id', params.sklik_id);
		}
		if (p.size && p.size > 0) {
			url += `?${p}`;
		}

		await $axios.patch(url, {}, auth);
	}

	@VuexAction({ rawError: true })
	async PAY_BOOKING(params: { booking_id: string; method: string }): Promise<any | boolean> {
		const auth = getAuthHeader();

		const url = `v2/booking/${params.booking_id}/pay?method=${params.method}`;

		const browser_fingerprint = getBrowserData();

		return await $axios
			.post(url, {three_d_secure_device_data: browser_fingerprint}, auth)
			.then((res: AxiosResponse) => {
				return res;
			})
			.catch((err: AxiosError) => {
				return ErrorResponse.fromAxiosError(err);
			});
	}

	@VuexAction({ rawError: true })
	async TRACKING_DONE(booking_id: string): Promise<void> {
		const url = `v2/booking/${booking_id}/tracking`;
		const auth = getAuthHeader();
		await $axios.patch(url, {}, auth);
	}

	@VuexAction({ rawError: true })
	async REQUEST_DEPOSIT_REFUND(params: { booking_id: string; payload: any }): Promise<boolean | ErrorResponse> {
		const url = `v2/booking/${params.booking_id}/deposit/refund`;
		const auth = getAuthHeader();

		return await $axios
			.post(url, params.payload, auth)
			.then((_res: AxiosResponse) => {
				return true;
			})
			.catch((err: AxiosError) => {
				return ErrorResponse.fromAxiosError(err);
			})
	}

	@VuexAction({ rawError: true })
	async CONVERT_DEPOSIT(booking_id: string): Promise<boolean | ErrorResponse> {
		const url = `v2/booking/${booking_id}/deposit/convert`;
		const auth = getAuthHeader();

		return await $axios
			.put(url, {}, auth)
			.then((_res: AxiosResponse) => {
				return true;
			})
			.catch((err: AxiosError) => {
				return ErrorResponse.fromAxiosError(err);
			})
	}

	@VuexAction({ rawError: true })
	async CREATE_UPGRADE(params: { id: string; payload: any }): Promise<any> {
		const url = `v2/booking/${params.id}/upgrade`;
		const auth = getAuthHeader();

		return await $axios
			.post(url, params.payload, auth)
			.then((res: AxiosResponse) => {
				return res.data;
			})
			.catch((err: AxiosError) => {
				return ErrorResponse.fromAxiosError(err);
			})
	}

	@VuexAction({ rawError: true })
	async GET_UPGRADE(params: {booking_id: string, upgrade_id: string}): Promise<Upgrade | ErrorResponse> {
		const auth = getAuthHeader();
		const expands = ['billing', 'booking:min', 'pay_methods'];

		const url = `v2/booking/${params.booking_id}/upgrade/${params.upgrade_id}` + stringifyExpands(expands);

		return await $axios
			.get(url, auth)
			.then((res: AxiosResponse) => {
				return new Upgrade(res.data);
			})
			.catch((err: AxiosError) => {
				return ErrorResponse.fromAxiosError(err);
			})
	}

	@VuexAction({ rawError: true })
	async GET_UPGRADE_LIST(id: string): Promise<any | ErrorResponse> {
		const auth = getAuthHeader();

		const order = new ListRequestOrder().addSorting('date_create', 'desc');

		const filter = new ListRequestFilter();
		filter.addFilter('state', 'eq', 'O');

		const url = `v2/booking/${id}/upgrade` + buildListQuery(0, 99, filter, order);

		return await $axios
			.get(url, auth)
			.then((res: AxiosResponse) => {
				return res.data;
			})
			.catch((err: AxiosError) => {
				return ErrorResponse.fromAxiosError(err);
			})
	}

	@VuexAction({ rawError: true })
	async PAY_UPGRADE(params: { booking_id: string; upgrade_id: string; type: string }): Promise<any> {
		const url = `v2/booking/${params.booking_id}/upgrade/${params.upgrade_id}/pay`;
		const auth = getAuthHeader();
		const browser_fingerprint = getBrowserData();

		return await $axios
			.post(url, { method: params.type, three_d_secure_device_data: browser_fingerprint }, auth)
			.then((res: AxiosResponse) => {
				return res;
			})
			.catch((err: AxiosError) => {
				return ErrorResponse.fromAxiosError(err);
			})
	}

	@VuexAction({ rawError: true })
	async FUEL_CHECK(id: string): Promise<any> {
		const url = `v2/booking/${id}/fuelcheck`;
		const auth = getAuthHeader();

		try {
			const res = await $axios.get(url, auth).then((res: AxiosResponse) => res.data);
			return res;
		} catch (err) {
			console.error('FUELCHECK failed with error: ', err);
		}
		return false;
	}

	@VuexAction({ rawError: true })
	async DISPUTE_CHECK(id: string): Promise<any> {
		const url = `v2/booking/${id}/fuelcheck`;
		const auth = getAuthHeader();

		try {
			const res = await $axios.put(url, {}, auth).then((res: AxiosResponse) => res.data);
			return res;
		} catch (err) {
			console.error('DISPUTE_CHECK failed with error: ', err);
		}
		return false;
	}

	@VuexAction({ rawError: true })
	async UPLOAD_FUEL_RECEIPT(params: { id: string; file: File }): Promise<any> {
		const url = `v2/booking/${params.id}/fuelcheck/upload`;
		const auth = getAuthHeader() as any;

		const formData = new FormData();
		formData.append('file', params.file);
		auth.headers['Content-Type'] = 'multipart/form-data';

		try {
			const res = await $axios.post(url, formData, auth).then((res: AxiosResponse) => res.data);
			return res;
		} catch (err) {
			console.error('UPLOAD_FUEL_RECEIPT failed with error: ', err);
		}
		return false;
	}

	@VuexAction({ rawError: true })
	async UPLOAD_DAMAGE_DOC(params: { booking_id: string; file: File }): Promise<any> {
		const url = `v2/booking/${params.booking_id}/damage/upload`;
		const auth = getAuthHeader() as any;

		const formData = new FormData();
		formData.append('file', params.file);
		auth.headers['Content-Type'] = 'multipart/form-data';

		return await $axios
			.post(url, formData, auth)
			.then((res: AxiosResponse) => {
				return res;
			})
			.catch((err: AxiosError) => {
				return ErrorResponse.fromAxiosError(err);
			})
	}

	@VuexAction({ rawError: true })
	async GET_APP_LINK(params: { booking_id: string }): Promise<{url:string, long_url?:string}> {
		const url = `v2/booking/${params.booking_id}/link`;
		const auth = getAuthHeader();

		return await $axios
			.get(url, auth)
			.then((res: AxiosResponse) => {
				return res.data;
			})
			.catch((err: AxiosError) => {
				return ErrorResponse.fromAxiosError(err);
			})
	}
}

export default getModule(BOOKING);
