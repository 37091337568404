import { AxiosError, AxiosResponse } from 'axios';
import { Module, VuexModule, VuexMutation, getModule, VuexAction } from 'nuxt-property-decorator';
import { store } from '@/store';
import { router, routesNames } from '@/router';
import { $axios } from '@/utils/api';

import Booking from '@/types/booking/booking';
import { Customer } from '@/types/customer/customer';
import { Invoice } from '@/types/customer/invoice';

import {
  getAuthHeader,
  getUserFromToken,
  tokenIsDeprecated,
  userTokenIsValid,
  getBrowserData
} from '@/_helpers/misc_helper';

import CART from '@/store/modules/CartModule';
import {
  ErrorResponse,
  ListRequestFilter,
  ListRequestOrder,
  ListResponse,
  buildListQuery,
  stringifyExpands,
} from '~/types/api_helper';

export interface AuthResponse {
  access_token: string;
  access_token_expires: number;
  refresh_token: string;
  refresh_token_expires: number;
}

@Module({
  name: 'USER',
  store, // this basically injects the module in the store dynamically thanks to next line
  dynamic: true,
  stateFactory: true, // apparently necessary/better for Nuxt
})
class USER extends VuexModule {
  data = new Customer();
  is_logged_in = false;
  cookie_checked = false;

  // ------------------------------------------------
  // ------------- Mutations ------------------------
  // ------------------------------------------------

  @VuexMutation
  setLoggedInState(bool: boolean) {
    this.is_logged_in = bool;

    if (!bool) {
      (window as any).enhanced_conversion_data = {};
    }
  }

  @VuexMutation
  storeUserData(user: Customer) {
    this.data = new Customer(user);

    // expose current user-data for enhanced google tracking
    (window as any).enhanced_conversion_data = {
      email: user.email,
      phone_number: user.phone,
      address: {
        first_name: user.firstname,
        last_name: user.lastname,
        street: user.address.street,
        city: user.address.city,
        postal_code: user.address.postcode,
        country: user.address.country,
      },
    };
  }

  @VuexMutation
  cookieChecked(bool: boolean) {
    this.cookie_checked = bool;
  }

  // ------------------------------------------------
  // ------------- Actions --------------------------
  // ------------------------------------------------

  @VuexAction({ rawError: true })
  async SIGNUP(params: {
    email: string;
    password?: string;
    newsletter?: boolean;
    origin?: string;
  }): Promise<AuthResponse | ErrorResponse> {
    return await $axios
      .post('v2/customer/signup', params)
      .then((res: AxiosResponse) => {
        return res.data as AuthResponse;
      })
      .catch((err: AxiosError) => {
        return ErrorResponse.fromAxiosError(err);
      });
  }

  @VuexAction({ rawError: true })
  async LOGIN(params: { email: string; password: string }): Promise<boolean | ErrorResponse> {
    const data = { user: params.email, password: params.password };

    return await $axios
      .post('v2/auth', data)
      .then(async (res: AxiosResponse) => {
        const token = res.data.access_token;
        if (localStorage) {
          localStorage.setItem('JStoken', token as string);
        }
        this.context.commit('setLoggedInState', true);
        await this.context.dispatch('GET_USER_DATA', token);
        return true;
      })
      .catch((err: AxiosError) => {
        return ErrorResponse.fromAxiosError(err);
      });
  }

  @VuexAction({ rawError: true })
  async LOGIN_PASSIVE(): Promise<boolean> {
    const localStorage_ok = typeof window !== 'undefined' && window.localStorage !== null;
    // Handles users still having v1 tokens stored and if so clearing everything.
    if (localStorage_ok && tokenIsDeprecated()) {
      // Clear token from localstorage / Clear user
      (this as any).store.$logger.console({ message: 'Token deprecated -> Clearing' });
      localStorage.removeItem('JStoken');
      this.context.commit('setLoggedInState', false);
      this.context.commit('storeUserData', new Customer());
      return false;
    }

    if (localStorage_ok && userTokenIsValid()) {
      (this as any).store.$logger.console({
        message: 'Valid token found - Logging user passively',
      });
      await this.context.dispatch('GET_USER_DATA', localStorage.getItem('JStoken') as string);
      this.context.commit('setLoggedInState', true);
      return true;
    } else {
      return false;
    }
  }

  @VuexAction({ rawError: true })
  async GENERATE_APP_LOGIN_LINK(): Promise<string | ErrorResponse> {
    const url = 'v2/auth/link';
    const auth = getAuthHeader();

    return await $axios
      .get(url, auth)
      .then((res: AxiosResponse) => {
        console.log(res);
        return res.data.link;
      })
      .catch((err: AxiosError) => {
        return ErrorResponse.fromAxiosError(err);
      });
  }

  @VuexAction
  async LOGOUT(): Promise<undefined | boolean> {
    const auth = getAuthHeader();
    try {
      await $axios.delete('v2/auth', auth).then(() => {
        (this as any).store.$logger.console({ message: 'Logout success - Deleting user' });
        if (localStorage) {
          localStorage.removeItem('JStoken');
        }
        this.context.commit('setLoggedInState', false);
        this.context.commit('storeUserData', new Customer());

        const cart_with_customer = !!CART.booking.customer && !!CART.booking.id;

        if (cart_with_customer) {
          (this as any).store.$logger.console({ message: 'Logout - Clear Cart' });
          CART.CLEAR_BOOKING_STORED();
        }

        if (
          router.currentRoute.matched.some((record) =>
            [routesNames.checkout, routesNames.info, routesNames.account].includes(
              record.name as routesNames
            )
          )
        ) {
          // If we are on checkout refresh the page so the guard checks USER again
          router.go(0);
        } else {
          (this as any).store.$logger.console({ message: 'Logout - Go Home' });
          // Clear the client field in the booking if there is one otherwise there will be issues when creating/checking the booking
          if (CART.booking.customer) {
            const b = new Booking(CART.booking);
            b.customer = '';
            CART.STORE_BOOKING_DATA(b);
          }
          if (
            router.currentRoute.name !== routesNames.home &&
            router.currentRoute.name !== routesNames.login
          ) {
            router.push({ name: routesNames.home });
          }
        }
      });
    } catch (err: any) {
      console.error('LOGOUT failed with error: ', err.response);
      return false;
    }
  }

  @VuexAction({ rawError: true })
  async CHANGE_PASSWORD(params: {
    current?: string;
    new_pw: string;
  }): Promise<boolean | ErrorResponse> {
    const url = 'v2/auth/password';
    const auth = getAuthHeader();

    const payload = {
      old_password: params.current ? params.current : null,
      new_password: params.new_pw,
    };

    return await $axios
      .patch(url, payload, auth)
      .then(() => {
        return true;
      })
      .catch((err: AxiosError) => {
        return ErrorResponse.fromAxiosError(err);
      });
  }

  @VuexAction({ rawError: true })
  async DELETE_USER(id: string): Promise<boolean | any> {
    const url = `v2/customer/${id}`;
    const auth = getAuthHeader();

    return await $axios
      .delete(url, auth)
      .then(() => {
        return true;
      })
      .catch((err: AxiosError) => {
        return ErrorResponse.fromAxiosError(err);
      });
  }

  @VuexAction({ rawError: true })
  async GET_USER_DATA(token?: string): Promise<Customer | ErrorResponse> {
    const auth = token ? { headers: { Authorization: 'Bearer ' + token } } : getAuthHeader();

    // extract user ID from token
    const user_id = getUserFromToken(token);

    let url = `v2/customer/${user_id}`;
    // Always expand abo & overdue_invoices to show banner
    url += stringifyExpands(['overdue_invoices', 'abo']);

    return await $axios
      .get(url, auth)
      .then((res) => {
        this.context.commit('storeUserData', res.data);
        return new Customer(res.data);
      })
      .catch((err: AxiosError) => {
        return ErrorResponse.fromAxiosError(err);
      });
  }

  @VuexAction({ rawError: true })
  async UPDATE_USER_DATA(payload: Customer): Promise<Customer | ErrorResponse> {
    const auth = getAuthHeader();
    let url = `v2/customer/${this.data.id}`;

    url += stringifyExpands(['overdue_invoices', 'abo']);

    return await $axios
      .put(url, payload.toPayload(), auth)
      .then((res) => {
        this.context.commit('storeUserData', res.data);
        return new Customer(res.data);
      })
      .catch((err: AxiosError) => {
        return ErrorResponse.fromAxiosError(err);
      });
  }

  @VuexAction({ rawError: true })
  async CHECK_INFO_VALIDITY(payload: {
    email: string;
    phone: string;
  }): Promise<boolean | ErrorResponse> {
    if (payload.phone) {
      payload.phone = payload.phone.replace(/ /g, '').replace('+', '00');
    }

    const query = payload.email !== '' ? '?email=' + payload.email : '?phone=' + payload.phone;
    const url = 'v2/customer/check' + query;
    const auth = getAuthHeader();

    return await $axios
      .get(url, auth)
      .then(() => {
        return true;
      })
      .catch((err: AxiosError) => {
        return ErrorResponse.fromAxiosError(err);
      });
  }

  @VuexAction({ rawError: true })
  async RESET_PASSWORD_REQUEST(email: string): Promise<boolean> {
    return await $axios
      .get(`v2/auth/reset?email=${email}`)
      .then(() => {
        return true;
      })
      .catch(() => {
        return false;
      });
  }

  @VuexAction({ rawError: true })
  async SET_RESET_PASSWORD(params: { token: string; pw: string }): Promise<any> {
    const url = `/v2/auth/reset?token=${params.token}&new_pwd=${encodeURIComponent(params.pw)}`;

    try {
      await $axios.put(url).then((res) => res.data);
      return true;
    } catch (err: any) {
      console.error('SET_RESET_PASSWORD failed with error: ', err.response);
      return false;
    }
  }

  @VuexAction({ rawError: true })
  async GET_MAGIC_LINK(contact: { contact: string }): Promise<any | boolean> {
    return await $axios
      .post('/v2/auth/magic', contact)
      .then(() => {
        return true;
      })
      .catch((err) => {
        console.error('GET_MAGIC_LINK failed with error: ', err.response);
        return false;
      });
  }

  @VuexAction({ rawError: true })
  async VALIDATE_MAGIC_LINK(ml: string): Promise<boolean | undefined> {
    if (this.is_logged_in) {
      const tk = atob(ml);
      const magicId = tk.substring(0, tk.indexOf('.'));
      (this as any).store.$logger.console({ message: 'ML detected - user logged in..' });
      if (this.data.id === magicId) {
        (this as any).store.$logger.console({ message: 'ML detected - IDs match...Continuing' });
        return true;
      } else {
        (this as any).store.$logger.console({
          message: "ML detected - IDs don't match... logging out user..",
        });
        await this.context.dispatch('LOGOUT');
      }
    }
    (this as any).store.$logger.console({ message: 'Checking ML Token validity' });

    return await $axios
      .get('v2/auth/magic?token=' + ml)
      .then(async (res: AxiosResponse) => {
        (this as any).store.$logger.console({ message: 'ML token valid - Logging in..' });
        const token = res.data.access_token;
        if (localStorage) {
          localStorage.setItem('JStoken', token);
        }
        this.context.commit('setLoggedInState', true);
        await this.context.dispatch('GET_USER_DATA', token as string);
        return true;
      })
      .catch((err: AxiosError) => {
        return false;
      });
  }

  @VuexAction({ rawError: true })
  async GET_BOOKINGS(): Promise<ListResponse<Booking> | ErrorResponse> {
    const auth = getAuthHeader();

    const order = new ListRequestOrder().addSorting('time_end', 'desc');
    const expands = [
      'truck',
      'customer:min',
      'international',
      'driver',
      'step',
      'billing',
      'documents',
      'deposit',
      'tracking',
    ];

    const filter = new ListRequestFilter();
    filter.addFilter('state', 'eq', 'C');

    const url = 'v2/booking' + buildListQuery(0, 99, filter, order, expands);

    return await $axios
      .get(url, auth)
      .then((res: AxiosResponse) => {
        return new ListResponse<Booking>(Booking, res.data);
      })
      .catch((err: AxiosError) => {
        return ErrorResponse.fromAxiosError(err);
      });
  }

  @VuexAction({ rawError: true })
  async GET_USER_INVOICES(user_id: string): Promise<ListResponse<Invoice> | ErrorResponse> {
    const auth = getAuthHeader();

    const order = new ListRequestOrder().addSorting('date_create', 'desc');
    const filter = new ListRequestFilter();
    const expands = ['documents'];
    // Filter by customer id just in case
    filter.addFilter('customer', 'eq', user_id);

    const url = 'v2/invoice' + buildListQuery(0, 99, filter, order, expands);

    return await $axios
      .get(url, auth)
      .then((res: AxiosResponse) => {
        return new ListResponse<Invoice>(Invoice, res.data);
      })
      .catch((err: AxiosError) => {
        return ErrorResponse.fromAxiosError(err);
      });
  }

  @VuexAction({ rawError: true })
  async OBJECT_INVOICE(params: { id: string; text: string }): Promise<any> {
    const url = `v2/invoice/${params.id}/object`;
    const auth = getAuthHeader();

    return await $axios
      .post(url, { text: params.text }, auth)
      .then((_res: AxiosResponse) => {
        return true;
      })
      .catch((err: AxiosError) => {
        return ErrorResponse.fromAxiosError(err);
      });
  }

  @VuexAction({ rawError: true })
  async FETCH_EXTRA_PAYMENT_INFO(id: string): Promise<Invoice | ErrorResponse> {
    let url = `v2/invoice/${id}`;
    const auth = getAuthHeader();

    url += stringifyExpands(['documents', 'pay_methods'], true);

    return await $axios
      .get(url, auth)
      .then((res: AxiosResponse) => {
        return res.data;
      })
      .catch((err: AxiosError) => {
        return ErrorResponse.fromAxiosError(err);
      });
  }

  @VuexAction({ rawError: true })
  async PAY_EXTRA_PAYMENT(params: { id: string; type: string }): Promise<any> {
    const url = `v2/invoice/${params.id}/pay?method=${params.type}`;

    const auth = getAuthHeader();
	const browser_fingerprint = getBrowserData();

    return await $axios
      .post(url, {three_d_secure_device_data: browser_fingerprint}, auth)
      .then((res: AxiosResponse) => {
        return res;
      })
      .catch((err: AxiosError) => {
        return ErrorResponse.fromAxiosError(err);
      });
  }

  // ---------------- Deprecated ---------------
  @VuexAction({ rawError: true })
  async CREATE_ABO(param: { bu: string; type: string }): Promise<any | boolean> {
    const url = `/abo/create?type=${param.type}&bu=${param.bu.toUpperCase()}`;

    const auth = getAuthHeader();

    return await $axios
      .get(url, auth)
      .then(async (resp) => {
        await this.context.dispatch('GET_USER_DATA');
        return resp;
      })
      .catch(async (err: any) => {
        if (err.response.status === 409 && err.response.data === 'ALREADY_EXISTS') {
          await this.context.dispatch('GET_USER_DATA');
          return true;
        }

        return { status: err.response.status, data: err.response.data };
      });
  }

  @VuexAction({ rawError: true })
  async REVOKE_ABO(cust_id: string): Promise<boolean | any> {
    const url = `v2/customer/${cust_id}/pro-abo/revoke`;

    const auth = getAuthHeader();

    return await $axios
      .delete(url, auth)
      .then(async () => {
        await this.context.dispatch('GET_USER_DATA');
        return true;
      })
      .catch((err: AxiosError) => {
		return ErrorResponse.fromAxiosError(err);
      });
  }

  @VuexAction({ rawError: true })
  async CANCEL_ABO(params: { user_id: string; payload: any }): Promise<any | boolean> {
    const url = `v2/customer/${params.user_id}/pro-abo/cancel`;

    const auth = getAuthHeader();

    return await $axios
      .put(url, params.payload, auth)
      .then(async (res: AxiosResponse) => {
        await this.context.dispatch('GET_USER_DATA');
        console.log('success on abo cancel', res.data);
        return res.data;
      })
      .catch((err: AxiosError) => {
        return ErrorResponse.fromAxiosError(err);
      });
  }
}

export default getModule(USER);
